import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Grid } from '@material-ui/core';

import { ToastContainer } from 'react-toastify';

import utils from '../../utils/Utils';
import i18n from '../../config/lang/i18n';
import Footer from '../layout-component/Footer';
import '../../assets/login.scss';
import queryString from 'query-string';
import ChannelService from 'utils/ChannelTalk';

class PwChangeCheck extends Component {
  constructor(props) {
    super(props);

    this.pwChangeCheckNext = this.pwChangeCheckNext.bind(this);

    this.state = {
      userId: '',
      userIdReadonly: false,
      password: ''
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    let { userId } = query;

    if (utils.string.isNotBlank(userId)) {
      this.setState({
        userId: decodeURIComponent(userId),
        userIdReadonly: true
      });
    }
  }

  pwChangeCheckNext() {
    const query = queryString.parse(this.props.location.search);
    utils.auth.removeToken();
    this.props.history.push('/login');
    // APIUtils.user.extendsChangePassword({
    //   body: {}
    // }, (data) => {
    //   if (data.status === 200 && data.success === true) {

    //   } else {
    //   }
    // });
    // if (utils.string.isBlank(query.returnUrl)) {

    //   if(utils.auth.hasSuperAdmin()) {
    //     this.props.history.push('/admin/dashboard');
    //   } else {
    //     this.props.history.push('/projectsDashboard');
    //   }
    // } else {
    //   let returnUrl = atob(query.returnUrl);
    //   if (window.location.href.indexOf("https") > -1) {
    //     if (returnUrl.indexOf('http:') > -1) {
    //       returnUrl = returnUrl.replace(/http:/ig, 'https:');
    //     }
    //   }
    //   window.location.href  = returnUrl;
    // }
  }

  render() {
    const { history } = this.props;

    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth="md">
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid
                          item
                          md={12}
                          className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div className="d-flex justify-conten-start align-items-center">
                            <div
                              className={`${
                                process.env.REACT_APP_ICONS === 'gov'
                                  ? 'gov-logo-style'
                                  : 'logo-style'
                              } mt-3`}></div>
                            <div className="text-white ml-3">
                              <h1>
                                {i18n.t(
                                  'account.changingYourPw',
                                  '비밀번호 변경 안내'
                                )}{' '}
                              </h1>
                            </div>
                          </div>
                          <div className="MuiPaper-root MuiCard-root card-box MuiPaper-elevation1 MuiPaper-rounded mb-3 bg-white">
                            <div className="MuiCardContent-root p-4 text-height-3">
                              <h4>
                                {i18n.t(
                                  'account.changingContent',
                                  '회원님께서 장기간 비밀번호를 변경하지 않고, 동일한 비밀번호를 사용중입니다. 오픈나루에서는 개인정보 보호와 안전한 포털시스템 사용을 위해 3개월마다 비밀번호 변경을 권장하고 있습니다. 소중한 개인정보 보호를 위해 비밀번호를 변경해주세요.'
                                )}
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center w-100 align-items-center">
                            <Button
                              className="button-maru-reversed p-2 font-size-sm"
                              onClick={(e) => {
                                history.push('/ChangePw');
                                e.preventDefault();
                              }}>
                              {i18n.t(
                                'account.pwChangeCheckNow',
                                '지금 변경하기'
                              )}
                            </Button>
                            <Button
                              className="button-maru-reversed p-2 font-size-sm ml-5"
                              onClick={(event) => {
                                this.pwChangeCheckNext();
                              }}>
                              {i18n.t(
                                'account.pwChangeCheckNext',
                                '다음에 변경하기'
                              )}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </div>
              </div>
              {ChannelService.showButton()}
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PwChangeCheck);
