import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';

import { ToastContainer } from 'react-toastify';

import i18n from '../../config/lang/i18n';
import Footer from '../layout-component/Footer';
import '../../assets/login.scss';

import ChannelService from 'utils/ChannelTalk';

class PasswordHelp extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth="md">
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid
                          item
                          md={12}
                          className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div className="d-flex justify-conten-start align-items-center">
                            <div
                              className={`${
                                process.env.REACT_APP_ICONS === 'gov'
                                  ? 'gov-logo-style'
                                  : 'logo-style'
                              } mt-3`}></div>
                            <div className="text-white ml-3">
                              <h1>
                                {i18n.t(
                                  'signup.passwordHelp',
                                  '비밀번호 도움말'
                                )}{' '}
                              </h1>
                            </div>
                          </div>
                          <div className="MuiPaper-root MuiCard-root card-box MuiPaper-elevation1 MuiPaper-rounded mb-3 bg-white">
                            <div className="MuiCardContent-root p-4 text-height-3">
                              <h4>
                                {i18n.t(
                                  'signup.passwordHelpItem1',
                                  '1. 8~100 자를 비밀번호로 사용할 수 있으며, 영문, 숫자, 특수문자 가 최소 하나씩 포함되어야 합니다.'
                                )}
                              </h4>
                              <h4>
                                {i18n.t(
                                  'signup.passwordHelpItem2',
                                  '2. 사용가능한 특수문자 : ? = @ $ ! % * ? & ^ ( ) _ + ` { } | : < > '
                                )}
                              </h4>
                              <h4>
                                {i18n.t(
                                  'signup.passwordHelpItem3',
                                  '3. 비밀번호는 3~6개월에 한번씩 주기적으로 바꾸어 사용하시는 것이 안전합니다.(3개월에 한번 비밀번호 변경을 고지합니다)'
                                )}
                              </h4>
                              <h4>
                                {i18n.t(
                                  'signup.passwordHelpItem4',
                                  '4. 사용자 정보는 ‘탈퇴 후 3개월’ 동안 보관됩니다.'
                                )}
                              </h4>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </div>
              </div>
              {ChannelService.showButton()}
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PasswordHelp);
