import React, { Component } from 'react';

import {
  Button,
  Container,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Checkbox
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import i18n from '../../config/lang/i18n';
import APIUtils from '../../utils/APIUtils';
import utils from '../../utils/Utils';
import notification from '../component/Notification';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import queryString from 'query-string';
import ChannelService from 'utils/ChannelTalk';

export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
};

class Signup extends Component {
  constructor(props) {
    super(props);

    this.defaultTimerValue = 300000; // 5분
    // this.defaultTimerValue = 10000; // 5분

    this.state = {
      userId: '',
      name: '',
      mobile: '',
      password: '',
      company: '',
      team: '',
      promoCd: '',
      email: '',
      emailReadonly: false,
      confirmPw: '',
      emailCertNumber: '',
      passwordValid: false,
      passwordSameCheckMessage: '',
      userIdCheckMessage: '',
      mobileCheckMessage: '',
      emailCheckMessage: '',
      emailCertNumberCheckMessage: '',
      emailCertNumberIncompleteMessage: '',
      emailCertTimer: undefined,
      emailCertTimerMilisecond: this.defaultTimerValue,
      isEmailSendClicked: false,
      isEmailCertNumberComplete: false,
      isUserIdValid: true,
      isEmailCertNumberChecked: false,
      isUserIdOverlap: false,
      isClicked: false,
      userImg: undefined,
      createErrorMessage: '',
      excludeEmails: [],
      checkItemList: [
        { id: 'checkPrivacy' },
        { id: 'checkTerms' },
        { id: 'checkMarketing' },
        { id: 'checkMarketingForEmail' }
      ],
      checkedItems: []
    };

    this.userIdInput = React.createRef();
    this.nameInput = React.createRef();
    this.passwordInput = React.createRef();
    this.mobileInput = React.createRef();
    this.emailInput = React.createRef();

    this.setInputValue = this.setInputValue.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.sendEmailCertNumber = this.sendEmailCertNumber.bind(this);
    this.checkEmailCertNumber = this.checkEmailCertNumber.bind(this);
    this.checkUserId = this.checkUserId.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onPopup = this.onPopup.bind(this);
    this.onCheckPopup = this.onCheckPopup.bind(this);
    this.handleAllCheck = this.handleAllCheck.bind(this);
    this.handleSingleCheck = this.handleSingleCheck.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    let { email, pc } = query;
    if (utils.string.isNotBlank(email)) {
      this.setState({ email: decodeURIComponent(email), emailReadonly: true });
    }
    if (utils.string.isNotBlank(pc)) {
      this.setState({ promoCd: pc });
    }

    APIUtils.user.excludeEmails({}, (data) => {
      if (data.status === 200) {
        this.setState({ excludeEmails: data.body });
      }
    });
  }

  setInputValue(event) {
    const inputId = event.target.id;
    const inputValue = event.target.value;
    let changeStateValue = { [inputId]: inputValue };

    this.setState(changeStateValue, () => {
      if (inputId === 'userId') {
        if (inputId !== '' && utils.string.checkUserId(this.state.userId)) {
          this.setState({ isUserIdValid: true });
        } else {
          this.setState({ isUserIdValid: false });
        }
      }
      if (inputId === 'password' || inputId === 'confirmPw') {
        if (this.state.password === this.state.confirmPw) {
          if (
            this.state.password !== '' &&
            utils.string.checkPassword(this.state.password)
          ) {
            this.setState({
              passwordSameCheckMessage: i18n.t(
                'signup.pwdPassMsg',
                '비밀번호가 일치하고, 보안에 적합합니다.'
              ),
              passwordValid: true
            });
          } else {
            this.setState({
              passwordSameCheckMessage: i18n.t(
                'signup.pwdRegexpMsg',
                '비밀번호는 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 8자리 이상 입력'
              ),
              passwordValid: false
            });
          }
        } else {
          this.setState({
            passwordSameCheckMessage: i18n.t(
              'signup.pwdDifferentMsg',
              '비밀번호가 일치하지 않습니다.'
            ),
            passwordValid: false
          });
        }
      }

      if (inputId === 'mobile') {
        if (
          this.state.mobile !== '' &&
          utils.string.checkMobile(this.state.mobile)
        ) {
          this.setState({ mobileCheckMessage: '' });
        } else {
          this.setState({
            mobileCheckMessage: i18n.t(
              'signup.mobileRegexpMsg',
              '모바일 번호가 유효하지 않습니다.'
            )
          });
        }
      }
      if (inputId === 'email') {
        if (
          this.state.email !== '' &&
          utils.string.checkEmail(this.state.email)
        ) {
          this.setState({ emailCheckMessage: '' });
        } else {
          this.setState({
            emailCheckMessage: i18n.t(
              'signup.emailRegexpMsg',
              '이메일 주소가 유효하지 않습니다.'
            )
          });
        }
      }
    });
  }

  registerUser() {
    this.setState({ mobileCheckMessage: '' });
    this.setState({ emailCheckMessage: '' });
    this.setState({ emailCertNumberIncompleteMessage: '' });
    if (
      !this.state.checkedItems.includes('checkPrivacy') ||
      !this.state.checkedItems.includes('checkTerms')
    ) {
      notification.error('필수항목을 체크해주세요.');
      return;
    }
    if (!utils.string.checkUserId(this.state.userId)) {
      this.userIdInput.current.focus();
      this.setState({ isUserIdValid: false });
      return;
    }
    if (this.state.name === '') {
      this.nameInput.current.focus();
      return;
    }
    if (!utils.string.checkPassword(this.state.password)) {
      this.setState({
        passwordValid: false,
        passwordSameCheckMessage: i18n.t(
          'signup.pwdRegexpMsg',
          '비밀번호는 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 9자리 이상 입력'
        )
      });
      this.passwordInput.current.focus();
      return;
    }
    if (this.state.password !== this.state.confirmPw) {
      this.setState({
        passwordValid: false,
        passwordSameCheckMessage: i18n.t(
          'signup.pwdDifferentMsg',
          '비밀번호가 일치하지 않습니다.'
        )
      });
      this.passwordInput.current.focus();
      return;
    }
    if (!utils.string.checkMobile(this.state.mobile)) {
      this.setState({
        mobileCheckMessage: i18n.t(
          'signup.mobileRegexpMsg',
          '모바일 번호가 유효하지 않습니다.'
        )
      });
      this.mobileInput.current.focus();
      return;
    }
    if (!utils.string.checkEmail(this.state.email)) {
      this.setState({
        emailCheckMessage: i18n.t(
          'signup.emailRegexpMsg',
          '이메일 주소가 유효하지 않습니다.'
        )
      });
      this.emailInput.current.focus();
      return;
    }

    if (this.state.isEmailCertNumberComplete === false) {
      this.setState({
        emailCheckMessage: i18n.t(
          'signup.emailCertIncompleteMsg',
          '이메일 인증 후 시도 하십시요.'
        )
      });
      return;
    }

    const history = this.props.history;

    this.setState({ isClicked: true });

    APIUtils.user.createUser(
      {
        body: {
          userId: this.state.userId,
          name: this.state.name,
          password: this.state.password,
          email: this.state.email,
          mobile: this.state.mobile,
          company: this.state.company,
          team: this.state.team,
          promoCd: this.state.promoCd,
          userImg: this.state.userImg,
          checkMarketing: this.state.checkedItems.includes('checkMarketing')
        }
      },
      (data) => {
        if (data.status === 201) {
          history.push(
            '/login' +
              (this.state.emailReadonly ? '?userId=' + this.state.userId : '')
          );
        } else {
          this.setState({ createErrorMessage: data.message });
        }
        this.setState({ isClicked: false });
      }
    );
  }

  checkUserId() {
    if (
      this.state.isUserIdValid === true &&
      utils.string.isNotEmpty(this.state.userId)
    ) {
      APIUtils.user.checkOverlap({ userId: this.state.userId }, (data) => {
        if (data.status === 200) {
          if (data.success) {
            this.setState({
              userIdCheckMessage: i18n.t(
                'signup.idOverlapFalseMsg',
                '사용가능한 아이디입니다.'
              ),
              isUserIdOverlap: false
            });
          } else {
            this.setState({
              userIdCheckMessage: i18n.t(
                'signup.idOverlapTrueMsg',
                '이미 존재하는 아이디 입니다.'
              ),
              isUserIdOverlap: true
            });
          }
        } else {
          notification.error(data.message);
        }
      });
    }
  }

  sendEmailCertNumber() {
    if (this.state.isEmailSendClicked) {
      return;
    }
    if (!utils.string.checkEmail(this.state.email)) {
      this.setState({
        emailCheckMessage: i18n.t(
          'signup.emailRegexpMsg',
          '이메일 주소가 유효하지 않습니다.'
        )
      });
      return;
    }

    // var isExcludeEmail = false;
    // var mailCompany = this.state.email.substring(this.state.email.indexOf("@") + 1);
    // this.state.excludeEmails.forEach(email => {
    //   if (mailCompany === email) {
    //     isExcludeEmail = true;
    //     return false;
    //   }
    // });

    // if (isExcludeEmail) {
    //   this.setState({
    //     emailCheckMessage: i18n.t(
    //       'signup.emailExcludeMsg',
    //       '공개 이메일은 가입 할 수 없습니다.'
    //     )
    //   });
    //   return;
    // }

    this.setState({ isEmailSendClicked: true });
    APIUtils.user.sendCertNumberMail(
      { body: { email: this.state.email } },
      (data) => {
        if (data.status === 200) {
          if (data.success === true) {
            this.setState({
              emailCheckMessage: i18n.t(
                'signup.emailCertSendedMsg',
                '인증번호가 발송 되었습니다. 메일을 확인해주세요.'
              )
            });
            this.setState({ emailCertTimerMilisecond: this.defaultTimerValue });

            if (this.state.emailCertTimer) {
              clearInterval(this.state.emailCertTimer);
            }

            let emailCertTimer = setInterval(() => {
              if (this.state.emailCertTimerMilisecond > 0) {
                this.setState({
                  emailCertTimerMilisecond:
                    this.state.emailCertTimerMilisecond - 1000
                });
              } else {
                clearInterval(this.state.emailCertTimer);
              }
            }, 1000);

            this.setState({
              emailCertTimer: emailCertTimer,
              isEmailCertNumberComplete: false,
              emailCertNumber: ''
            });
          } else {
            this.setState({ emailCheckMessage: data.message });
          }
        } else {
          this.setState({ emailCheckMessage: data.message });
        }
        this.setState({ isEmailSendClicked: false });
      }
    );
  }

  checkEmailCertNumber() {
    if (!utils.string.checkEmail(this.state.email)) {
      this.setState({
        emailCheckMessage: i18n.t(
          'signup.emailRegexpMsg',
          '이메일 주소가 유효하지 않습니다.'
        )
      });
      return;
    }

    if (
      this.state.emailCertNumber !== '' &&
      this.state.emailCertNumber.length === 3
    ) {
      APIUtils.user.checkCertNumberMail(
        {
          token: this.state.emailCertNumber,
          body: { email: this.state.email }
        },
        (data) => {
          if (data.status === 200) {
            if (data.success) {
              this.setState({
                isEmailCertNumberComplete: true,
                emailCertNumberCheckMessage: '',
                emailCheckMessage: ''
              });
            } else {
              this.setState({
                isEmailCertNumberComplete: false,
                emailCertNumberCheckMessage: data.message
              });
            }
          } else {
            this.setState({
              isEmailCertNumberComplete: false,
              emailCertNumberCheckMessage: data.message
            });
          }
        }
      );
    }
  }

  onDrop(acceptedFiles) {
    acceptedFiles.forEach((file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {}; // data url!
      fileToBase64(file).then((result) => {
        this.setState({ userImg: result });
      });
    });
  }
  onPopup = (e) => {
    e.preventDefault();
    const url = '/console/passwordHelp ';
    var popupX = document.body.offsetWidth / 2 - 600 / 2;
    var popupY = window.screen.height / 2 - 750 / 2;
    window.open(
      url,
      '_blank',
      'width=600,height=750,left=' + popupX + ', top=' + popupY
    );
  };
  onCheckPopup = (e, id) => {
    e.preventDefault();
    let url;
    if (id === 'checkPrivacy') {
      url = 'https://www.gov.openmaru.io/privacy-policy/';
    } else if (id === 'checkTerms') {
      url = 'https://www.gov.openmaru.io/terms-of-use/';
    } else {
      url = '/console/marketing';
    }

    var popupX = document.body.offsetWidth / 2 - 1000 / 2;
    var popupY = window.screen.height / 2 - 750 / 2;
    window.open(
      url,
      '_blank',
      'width=1000,height=750,left=' + popupX + ', top=' + popupY
    );
  };
  handleSingleCheck = (checked, id) => {
    console.log('v: ', id);

    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      if (id == 'checkMarketing') {
        this.setState({
          checkedItems: [
            ...this.state.checkedItems,
            id,
            'checkMarketingForEmail'
          ]
        });
      } else if (id == 'checkMarketingForEmail') {
        this.setState({
          checkedItems: [...this.state.checkedItems, id, 'checkMarketing']
        });
      } else {
        this.setState({ checkedItems: [...this.state.checkedItems, id] });
      }
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)

      if (id == 'checkMarketing') {
        this.setState(
          { checkedItems: this.state.checkedItems.filter((el) => el !== id) },
          () => {
            this.setState({
              checkedItems: this.state.checkedItems.filter(
                (el) => el !== 'checkMarketingForEmail'
              )
            });
          }
        );
      } else if (id == 'checkMarketingForEmail') {
        this.setState(
          { checkedItems: this.state.checkedItems.filter((el) => el !== id) },
          () => {
            this.setState({
              checkedItems: this.state.checkedItems.filter(
                (el) => el !== 'checkMarketing'
              )
            });
          }
        );
      } else {
        this.setState({
          checkedItems: this.state.checkedItems.filter((el) => el !== id)
        });
      }
    }
  };

  // 체크박스 전체 선택
  handleAllCheck = (checked) => {
    if (checked) {
      // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
      const idArray = [];
      this.state.checkItemList.forEach((el) => idArray.push(el.id));
      this.setState({ checkedItems: idArray });
    } else {
      // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
      this.setState({ checkedItems: [] });
    }
  };
  render() {
    const history = this.props.history;
    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth="md">
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid
                          item
                          md={5}
                          className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div
                            className={`${
                              process.env.REACT_APP_ICONS === 'gov'
                                ? 'gov-logo-style'
                                : 'logo-style'
                            } mt-3`}
                          />
                          <div className="w-100">
                            <div className="text-center pt-4 text-white">
                              <Button
                                className="w-50 button-maru-reversed p-2 font-size-sm"
                                onClick={(e) => {
                                  history.push('/login');
                                  e.preventDefault();
                                }}>
                                {i18n.t('login.title', '로그인')}
                              </Button>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={7}
                          className="d-flex align-items-center bg-white">
                          <div className="w-100 px-2">
                            <div className="text-black mt-3">
                              <span className="text-maru-color">
                                <h1 className="display-4 mb-5 font-weight-bold">
                                  {i18n.t('signup.createAccount', '회원 가입')}
                                </h1>
                              </span>
                              <div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t('userList.userId', '아이디')}
                                  </label>
                                  <label
                                    className="font-weight-bold mb-2"
                                    style={{
                                      color: 'red',
                                      paddingLeft: '5px'
                                    }}>
                                    *
                                  </label>
                                  <TextField
                                    id="userId"
                                    className="input-maru-custom"
                                    autoFocus={true}
                                    inputRef={this.userIdInput}
                                    inputProps={{ maxLength: 50 }}
                                    size="small"
                                    fullWidth
                                    type="text"
                                    onChange={this.setInputValue}
                                    value={this.state.userId}
                                    error={!this.state.isUserIdValid}
                                    helperText={i18n.t('signup.idRegexpMsg')}
                                    onBlur={() => this.checkUserId()}
                                  />
                                  <FormHelperText
                                    error={this.state.isUserIdOverlap}>
                                    {this.state.userIdCheckMessage}
                                  </FormHelperText>
                                </div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t('userList.name', '이름')}
                                  </label>
                                  <label
                                    className="font-weight-bold mb-2"
                                    style={{
                                      color: 'red',
                                      paddingLeft: '5px'
                                    }}>
                                    *
                                  </label>
                                  <TextField
                                    id="name"
                                    className="input-maru-custom"
                                    inputRef={this.nameInput}
                                    inputProps={{ maxLength: 50 }}
                                    size="small"
                                    fullWidth
                                    type="text"
                                    onChange={this.setInputValue}
                                    value={this.state.name}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t('signup.password', '비밀번호')}
                                  </label>
                                  <label
                                    className="font-weight-bold mb-2"
                                    style={{
                                      color: 'red',
                                      paddingLeft: '5px',
                                      paddingRight: '180px'
                                    }}>
                                    *
                                  </label>
                                  <label className=" mb-2">
                                    <a href="#" onClick={this.onPopup}>
                                      ?){' '}
                                      {i18n.t(
                                        'signup.passwordHelp',
                                        '비밀번호 도움말'
                                      )}
                                    </a>
                                  </label>
                                  <TextField
                                    id="password"
                                    className="input-maru-custom"
                                    inputRef={this.passwordInput}
                                    size="small"
                                    fullWidth
                                    type="password"
                                    inputProps={{ maxLength: 100 }}
                                    onChange={this.setInputValue}
                                    value={this.state.password}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label className="font-weight-bold mb-2">
                                    {i18n.t(
                                      'signup.passwordConfirm',
                                      '비밀번호 확인'
                                    )}
                                  </label>
                                  <label
                                    className="font-weight-bold mb-2"
                                    style={{
                                      color: 'red',
                                      paddingLeft: '5px'
                                    }}>
                                    *
                                  </label>
                                  <TextField
                                    id="confirmPw"
                                    className="input-maru-custom"
                                    size="small"
                                    fullWidth
                                    type="password"
                                    inputProps={{ maxLength: 100 }}
                                    onChange={this.setInputValue}
                                    value={this.state.confirmPw}
                                  />
                                  <FormHelperText
                                    error={!this.state.passwordValid}>
                                    {this.state.passwordSameCheckMessage}
                                  </FormHelperText>
                                </div>
                                <div className="mb-3">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <label className="font-weight-bold mb-2">
                                        {i18n.t('userList.mobile', '모바일')}
                                      </label>
                                      <label
                                        className="font-weight-bold mb-2"
                                        style={{
                                          color: 'red',
                                          paddingLeft: '5px'
                                        }}>
                                        *
                                      </label>
                                    </div>
                                  </div>
                                  <TextField
                                    className="input-maru-custom"
                                    id="mobile"
                                    onChange={this.setInputValue}
                                    value={this.state.mobile}
                                    customInput={TextField}
                                    inputRef={this.mobileInput}
                                    size="small"
                                  />
                                  <FormHelperText
                                    error={
                                      this.state.mobileCheckMessage !== ''
                                    }>
                                    {this.state.mobileCheckMessage}
                                  </FormHelperText>
                                </div>
                                <div className="mb-1">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <label className="font-weight-bold mb-2">
                                        {i18n.t('userList.email', '이메일')}
                                      </label>
                                      <label
                                        className="font-weight-bold mb-2"
                                        style={{
                                          color: 'red',
                                          paddingLeft: '5px'
                                        }}>
                                        *
                                      </label>
                                    </div>
                                  </div>
                                  <Grid
                                    container
                                    spacing={1}
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item md={7}>
                                      <TextField
                                        id="email"
                                        disabled={this.state.emailReadonly}
                                        className="input-maru-custom"
                                        inputRef={this.emailInput}
                                        size="small"
                                        fullWidth
                                        type="email"
                                        onChange={this.setInputValue}
                                        value={this.state.email}
                                      />
                                      <FormHelperText
                                        error={
                                          this.state.emailCheckMessage !== ''
                                        }>
                                        {this.state.emailCheckMessage}
                                      </FormHelperText>
                                    </Grid>
                                    <Grid
                                      item
                                      disabled={
                                        this.state.isEmailCertNumberComplete
                                      }>
                                      <Button
                                        className="btn-dark"
                                        onClick={() =>
                                          this.sendEmailCertNumber()
                                        }>
                                        {i18n.t(
                                          'signup.sendCert',
                                          '인증번호 발송'
                                        )}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>
                                <div
                                  className="mb-3"
                                  hidden={this.state.isEmailCertNumberComplete}>
                                  <Grid
                                    container
                                    spacing={1}
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item md={6}>
                                      <TextField
                                        id="emailCertNumber"
                                        className="input-maru-custom"
                                        inputProps={{ maxLength: 6 }}
                                        size="small"
                                        onChange={this.setInputValue}
                                        value={this.state.emailCertNumber}
                                      />
                                    </Grid>
                                    <Grid item md={3} className="text-left">
                                      <Typography>
                                        {utils.string.milisecondToStr(
                                          this.state.emailCertTimerMilisecond
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        className="btn-dark"
                                        onClick={() =>
                                          this.checkEmailCertNumber()
                                        }>
                                        {i18n.t('signup.checkCert', '확인')}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <FormHelperText
                                    error={
                                      this.state.emailCertNumberCheckMessage !==
                                      ''
                                    }>
                                    {this.state.emailCertNumberCheckMessage}
                                  </FormHelperText>
                                </div>

                                <Grid container>
                                  <Grid item md={6} className="p-0">
                                    <div className="mb-3">
                                      <div className="d-flex justify-content-between">
                                        <label className="font-weight-bold mb-2">
                                          {i18n.t('userList.company', '회사')}
                                        </label>
                                      </div>
                                      <TextField
                                        id="company"
                                        className="input-maru-custom"
                                        size="small"
                                        fullWidth
                                        type="text"
                                        onChange={this.setInputValue}
                                        value={this.state.company}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <div className="d-flex justify-content-between">
                                        <label className="font-weight-bold mb-2">
                                          {i18n.t('userList.team', '부서/팀')}
                                        </label>
                                      </div>
                                      <TextField
                                        id="team"
                                        className="input-maru-custom"
                                        size="small"
                                        fullWidth
                                        type="text"
                                        onChange={this.setInputValue}
                                        value={this.state.team}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <div className="d-flex justify-content-between">
                                        <label className="font-weight-bold mb-2">
                                          {i18n.t(
                                            'userList.promoCd',
                                            '프로모션 코드'
                                          )}
                                        </label>
                                      </div>
                                      <TextField
                                        id="promoCd"
                                        className="input-maru-custom"
                                        size="small"
                                        fullWidth
                                        type="text"
                                        onChange={this.setInputValue}
                                        value={this.state.promoCd}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item md={6} className="pl-4 pr-0 py-0">
                                    <div className="mb-3">
                                      <div className="d-flex justify-content-between">
                                        <label className="font-weight-bold mb-2">
                                          {i18n.t('signup.picture', '사진')}
                                        </label>
                                      </div>
                                      <Dropzone onDrop={this.onDrop}>
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragAccept,
                                          isDragReject,
                                          isDragActive
                                        }) => (
                                          <div
                                            {...getRootProps()}
                                            className="d-flex justify-content-center">
                                            <input {...getInputProps()} />
                                            <div className="dropzone-inner-wrapper rounded-circle dropzone-avatar">
                                              <div className="avatar-icon-wrapper d-100 rounded-circle m-2">
                                                <Button className="avatar-button badge shadow-xxl btn-icon badge-position badge-position--bottom-right border-0 text-indent-0 d-30 badge-circle btn-success hover-scale-lg text-white">
                                                  <EditTwoToneIcon className="w-50" />
                                                </Button>
                                                {this.state.userImg ===
                                                  undefined &&
                                                  isDragAccept && (
                                                    <div className="rounded-circle overflow-hidden d-100 bg-success text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                                      <CheckIcon className="d-30" />
                                                    </div>
                                                  )}
                                                {this.state.userImg ===
                                                  undefined &&
                                                  isDragReject && (
                                                    <div className="rounded-circle overflow-hidden d-100 bg-danger text-center font-weight-bold text-white d-flex justify-content-center align-items-center">
                                                      <CloseTwoToneIcon className="d-40" />
                                                    </div>
                                                  )}
                                                {this.state.userImg ===
                                                  undefined &&
                                                  !isDragActive && (
                                                    <div className="rounded-circle overflow-hidden d-100 bg-second text-center font-weight-bold text-white-50 d-flex justify-content-center align-items-center">
                                                      <AccountCircleTwoToneIcon className="d-30" />
                                                    </div>
                                                  )}
                                                {this.state.userImg !==
                                                  undefined && (
                                                  <div className="rounded-circle avatar-image overflow-hidden d-100 bg-neutral-success text-center font-weight-bold text-success d-flex justify-content-center align-items-center">
                                                    <img
                                                      className="img-fluid img-fit-container rounded-sm"
                                                      src={this.state.userImg}
                                                      alt="..."
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="my-4 text-left">
                                        {i18n.t(
                                          'common.uploadLayerMsg',
                                          'Drag and drop Or Click'
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="text-black font-size-sm">
                                <p>
                                  {i18n.t(
                                    'signup.sentence3',
                                    '전화번호는 경고 알림 문자에 사용됩니다.'
                                  )}
                                </p>
                                <p>
                                  {i18n.t(
                                    'signup.sentence4',
                                    '이메일은 패스워드 초기화, 이밴트 알림에 사용됩니다. 수신되지 않을 경우, 스팸 메일을 확인하여 주시기 바랍니다.'
                                  )}{' '}
                                </p>
                                <div>
                                  <label className="font-weight-bold">
                                    <Checkbox
                                      size="small"
                                      onChange={(e) =>
                                        this.handleAllCheck(e.target.checked)
                                      }
                                      // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                                      checked={
                                        this.state.checkedItems.length ===
                                        this.state.checkItemList.length
                                          ? true
                                          : false
                                      }
                                      name="select-all"
                                      color="primary"
                                    />
                                    {i18n.t('common.checkAll', '전체 동의')}
                                  </label>
                                </div>
                                {this.state.checkItemList?.map((item, key) => (
                                  <div key={key}>
                                    <label
                                      className={
                                        key !==
                                        this.state.checkItemList.length - 1
                                          ? 'font-weight-bold'
                                          : 'font-weight-bold email-checkbox-label'
                                      }>
                                      <Checkbox
                                        size="small"
                                        onChange={(e) =>
                                          this.handleSingleCheck(
                                            e.target.checked,
                                            item.id
                                          )
                                        }
                                        // 체크된 아이템 배열에 해당 아이템이 있을 경우 선택 활성화, 아닐 시 해제
                                        checked={
                                          this.state.checkedItems.includes(
                                            item.id
                                          )
                                            ? true
                                            : false
                                        }
                                        name={`select-${item.id}`}
                                        color="primary"
                                      />
                                      {i18n.t('common.' + item.id)}

                                      {key !==
                                        this.state.checkItemList.length - 1 && (
                                        <a
                                          href="#"
                                          onClick={(e) =>
                                            this.onCheckPopup(e, item.id)
                                          }>
                                          [{i18n.t('common.more', '자세히')}]
                                        </a>
                                      )}
                                    </label>
                                  </div>
                                ))}
                                {/* <p>{i18n.t('signup.sentence1', '‘회원 가입’을 클릭하면 오픈나루의')} 
                                  <a className="text-maru-color" href="https://www.openmaru.io/terms-of-use/" target="_blank" rel="noopener noreferrer"> {i18n.t('signup.termOfUse', '이용 약관')} </a>{i18n.t('signup.and', '과')} 
                                  <a className="text-maru-color" href="https://www.openmaru.io/terms-of-use/" target="_blank" rel="noopener noreferrer"> {i18n.t('signup.privacyPolicy', '개인정보 처리 정책')}</a>{i18n.t('signup.sentence2', '에 동의한 것으로 간주됩니다.')}
                                        </p> */}
                              </div>
                              <div>
                                <FormHelperText
                                  className="text-center"
                                  error={this.state.createErrorMessage !== ''}>
                                  {this.state.createErrorMessage}
                                </FormHelperText>
                              </div>
                              <div className="w-100 d-flex justify-content-center">
                                <Button
                                  // size=""
                                  // fullWidth
                                  className="w-50 button-maru-contained font-weight-bold font-size-sm"
                                  onClick={this.registerUser}>
                                  <span hidden={!this.state.isClicked}>
                                    <FontAwesomeIcon
                                      icon={['fas', 'cog']}
                                      spin
                                    />
                                    ...
                                  </span>
                                  <span hidden={this.state.isClicked}>
                                    {i18n.t('signup.createAccount', '회원가입')}
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                    <ToastContainer />
                  </div>
                </div>
              </div>
              {ChannelService.showButton()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Signup);
