import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Container, Grid, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';
import notification from 'openmaru/component/Notification';

import APIUtils from 'utils/APIUtils';
import utils from '../../utils/Utils';
import i18n from '../../config/lang/i18n';
import Footer from '../layout-component/Footer';
import '../../assets/login.scss';
import queryString from 'query-string';
import ChannelService from 'utils/ChannelTalk';

import CTextField from 'openmaru/component/CTextField';
import { FormHelperText } from '@material-ui/core';
import ConfirmDialog from 'openmaru/component/ConfirmDialog';
class ChangePw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: '',
      userIdReadonly: false,
      nowPassword: '',
      toPassword: '',
      toPasswordConfirm: '',
      passwordValid: false,
      passwordSameCheckMessage: '',
      showNowPassword: false,
      showToPassword: false,
      showToPasswordConfirm: false,
      showProfileContent: false,
      showAuthPassword: false,
      passwordAuth: ''
    };

    this.updateUserConfirmDialog = React.createRef();
    this.nowPasswordInput = React.createRef();
    this.toPasswordInput = React.createRef();

    this.setInputValue = this.setInputValue.bind(this);
    this.updateUserPassword = this.updateUserPassword.bind(this);
    this.passwordEditViewCancel = this.passwordEditViewCancel.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    let { userId } = query;

    if (utils.string.isNotBlank(userId)) {
      this.setState({
        userId: decodeURIComponent(userId),
        userIdReadonly: true
      });
    }
  }

  setInputValue(event) {
    const inputId = event.target.name;
    const inputValue =
      event.target.tagName === 'INPUT' && event.target.type === 'text'
        ? utils.string.unescape(event.target.value)
        : event.target.value;

    let changeStateValue = { [inputId]: inputValue };

    this.setState(changeStateValue, () => {
      this.setState({ inputId: inputValue });
    });
  }

  updateUserPassword() {
    if (!utils.string.checkPassword(this.state.toPassword)) {
      this.setState({
        passwordValid: false,
        passwordSameCheckMessage: i18n.t(
          'signup.pwdRegexpMsg',
          '비밀번호는 숫자, 특문 각 1회 이상, 영문은 2개 이상 사용하여 8자리 이상 입력'
        )
      });
      this.toPasswordInput.current.focus();
      return;
    }
    if (this.state.toPassword !== this.state.toPasswordConfirm) {
      this.setState({
        passwordValid: false,
        passwordSameCheckMessage: i18n.t(
          'signup.pwdDifferentMsg',
          '비밀번호가 일치하지 않습니다.'
        )
      });
      this.toPasswordInput.current.focus();
      return;
    }

    this.setState({ passwordValid: true, passwordSameCheckMessage: '' });

    this.updateUserConfirmDialog.current.open(
      {
        title: i18n.t('common.confirmEditMessage', '변경 하시겠습니까?')
      },
      () => {
        APIUtils.user.changePassword(
          {
            body: {
              password: this.state.nowPassword,
              changePassword: this.state.toPassword
            }
          },
          (data) => {
            if (data.status === 200 && data.success === true) {
              this.passwordEditViewCancel();
              notification.info(
                i18n.t('common.editSuccessful', '변경 되었습니다.')
              );
            } else {
              this.setState({ registerMessage: data.message });
            }
            this.setState({ isClicked: false });
          }
        );
      }
    );
  }

  passwordEditViewCancel() {
    const query = queryString.parse(this.props.location.search);

    if (utils.string.isBlank(query.returnUrl)) {
      if (utils.auth.hasSuperAdmin()) {
        this.props.history.push('/admin/dashboard');
      } else {
        this.props.history.push('/projectsDashboard');
      }
    } else {
      let returnUrl = atob(query.returnUrl);
      if (window.location.href.indexOf('https') > -1) {
        if (returnUrl.indexOf('http:') > -1) {
          returnUrl = returnUrl.replace(/http:/gi, 'https:');
        }
      }
      window.location.href = returnUrl;
    }
  }

  render() {
    const { history } = this.props;

    return (
      <>
        <div className="app-wrapper bg-white min-vh-100">
          <div className="app-main min-vh-100">
            <div className="app-content p-0 bg-maru-gray">
              <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--content py-5">
                    <Container maxWidth="md">
                      <Grid container spacing={10} className="maru-box-shadow">
                        <Grid
                          item
                          md={12}
                          className="d-flex flex-column justify-content-between align-items-center bg-maru-grady">
                          <div className="divider-v d-none d-lg-block divider-v-md" />
                          <div className="d-flex justify-conten-start align-items-center mb-3">
                            <div
                              className={`${
                                process.env.REACT_APP_ICONS === 'gov'
                                  ? 'gov-logo-style'
                                  : 'logo-style'
                              } mt-3`}></div>
                            <div className="text-white ml-3">
                              <h1>
                                {i18n.t(
                                  'account.changePassword',
                                  '비밀번호 변경'
                                )}
                              </h1>
                            </div>
                          </div>
                          <div className="w-100 p-5 MuiPaper-root MuiCard-root card-box MuiPaper-elevation1 MuiPaper-rounded bg-white">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between">
                                <label className="font-weight-bold mb-2">
                                  {i18n.t('account.nowPassword', '비밀번호')}
                                </label>
                              </div>
                              <CTextField
                                className="input-maru-custom"
                                inputRef={this.nowPasswordInput}
                                id="nowPassword"
                                name="nowPassword"
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter your password"
                                type={
                                  this.state.showNowPassword
                                    ? 'text'
                                    : 'password'
                                }
                                InputProps={{
                                  maxLength: 100,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <FontAwesomeIcon
                                        icon={[
                                          'far',
                                          this.state.showNowPassword
                                            ? 'eye'
                                            : 'eye-slash'
                                        ]}
                                        className="font-size-xl nav-link"
                                        onClick={(event) => {
                                          this.setState({
                                            showNowPassword: !this.state
                                              .showNowPassword
                                          });
                                        }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={this.setInputValue}
                                value={this.state.nowPassword}
                              />
                            </div>
                            <div className="mb-3">
                              <div className="d-flex justify-content-between">
                                <label className="font-weight-bold mb-2">
                                  {i18n.t('account.toPassword', '비밀번호')}
                                </label>
                              </div>
                              <CTextField
                                className="input-maru-custom"
                                inputRef={this.toPasswordInput}
                                id="toPassword"
                                name="toPassword"
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter your password"
                                type={
                                  this.state.showToPassword
                                    ? 'text'
                                    : 'password'
                                }
                                InputProps={{
                                  maxLength: 100,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <FontAwesomeIcon
                                        icon={[
                                          'far',
                                          this.state.showToPassword
                                            ? 'eye'
                                            : 'eye-slash'
                                        ]}
                                        className="font-size-xl nav-link"
                                        onClick={(event) => {
                                          this.setState({
                                            showToPassword: !this.state
                                              .showToPassword
                                          });
                                        }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={this.setInputValue}
                                value={this.state.toPassword}
                              />
                            </div>
                            <div className="mb-3">
                              <div className="d-flex justify-content-between">
                                <label className="font-weight-bold mb-2">
                                  {i18n.t(
                                    'account.toPasswordConfirm',
                                    '비밀번호 확인'
                                  )}
                                </label>
                              </div>
                              <CTextField
                                className="input-maru-custom"
                                id="toPasswordConfirm"
                                name="toPasswordConfirm"
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Enter your password"
                                type={
                                  this.state.showToPasswordConfirm
                                    ? 'text'
                                    : 'password'
                                }
                                InputProps={{
                                  maxLength: 100,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <FontAwesomeIcon
                                        icon={[
                                          'far',
                                          this.state.showToPasswordConfirm
                                            ? 'eye'
                                            : 'eye-slash'
                                        ]}
                                        className="font-size-xl nav-link"
                                        onClick={(event) => {
                                          this.setState({
                                            showToPasswordConfirm: !this.state
                                              .showToPasswordConfirm
                                          });
                                        }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={this.setInputValue}
                                value={this.state.toPasswordConfirm}
                              />
                              <FormHelperText error={!this.state.passwordValid}>
                                {this.state.passwordSameCheckMessage}
                              </FormHelperText>
                            </div>
                            <FormHelperText
                              error={this.state.registerMessage !== ''}>
                              {this.state.registerMessage}
                            </FormHelperText>

                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                              <Button
                                className="button-maru-contained text-uppercase font-weight-bold font-size-sm mt-3"
                                hidden={!this.state.isClicked}
                                onClick={this.registerUser}>
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={['fas', 'cog']} spin />
                                </span>
                                ...
                              </Button>
                              <Button
                                className="button-maru-contained text-uppercase font-weight-bold font-size-sm mt-3 ml-4"
                                hidden={this.state.isClicked}
                                onClick={(event) => {
                                  this.updateUserPassword();
                                }}>
                                {i18n.t('common.edit', '수정')}
                              </Button>

                              <Button
                                className="button-maru-outlined text-uppercase font-weight-bold font-size-sm mt-3 ml-4"
                                onClick={(event) => {
                                  this.passwordEditViewCancel();
                                }}>
                                {i18n.t('common.cancel', '취소')}
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                </div>
              </div>
              {ChannelService.showButton()}
            </div>
            <Footer />
            <ToastContainer />
          </div>
        </div>
        <ConfirmDialog ref={this.updateUserConfirmDialog} />
      </>
    );
  }
}

export default withRouter(ChangePw);
